@import '../Colors.scss';

// .css-mhc70k-MuiGrid-root>.MuiGrid-item{
    // padding-left: 0px !important;
    // margin-top: 16px;
// }

.aboutUsText{
    width: 100%;
    display: flex;
    justify-content: center;

    @media screen and (max-width:1150px) {
        height: auto;
        overflow-y: scroll;
    }
    @media screen and (max-width:1000px) {
        height: auto;
        overflow-y: scroll;
    }
    // @media screen and (max-width:900px) {
    //     height: 65vh;
    //     overflow-y: scroll;
    // }

    .container-Content{
        text-align: left;
          width:70%;
        //   text-align: justify;
          
          @media screen and (max-width:900px) {
              width:90%;
            //   text-align: justify;
        }
    
    }
}

.ContentAndPicContainer {
    @media screen and (max-width:900px) {
        display: flex !important;
        flex-direction: column-reverse !important;
    }
}

.container-logo{
    width: 80%;
    border-radius: 8px;

    @media screen and (max-width:900px) {
        width: 50%;
        // margin-bottom: 50px;

        @media screen and (min-width:800px) {
            // width: 50%;
            margin-bottom: 50px;
        }    
    }
    
    @media screen and (max-width:450px) {
        width: 80%;
        // margin-bottom: 0;
    }
}


.AboutUsTitle {
    color : $GoldenColor !important;
    margin: 32px 0px;
}

.ShowMoreButton {
    color : $GoldenColor !important;
    cursor: $SpineCursor;
    margin: auto !important;
}