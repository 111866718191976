@import '../../Colors.scss';


.contactUsContainer {
    height: 100vh;
    // display: flex !important;
    // align-items: center !important;
    margin: auto !important;
    margin-top: 10% !important;
    padding-bottom: 20% !important;
    // overflow-y: hidden !important;
    // overflow-y: scroll !important;
    
    @media screen and (max-width: 900px) {
      overflow-y: scroll !important;
        margin-top: 15% !important;
    }
  }
  
  .contactUsTitle {
    color: $GoldenColor !important;
  }

  .ContentContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    @media screen and (max-width: 900px) {
      flex-direction: column-reverse;
  }
}

.GridContainer {
  width: 50% !important;
  
  @media screen and (max-width: 900px) {
    width: 100% !important;
  }
}

.ContactUsMap  {
  width: 50% !important;
  
  @media screen and (max-width: 900px) {
    width: 100% !important;
    margin-left: 0 !important;
  }
  }

.ContactUsButton {
  color: $White !important;
  background-color: $GoldenColor !important;
  opacity: 0.75;
  margin-top: 10px !important;
  width: 150px;
  
  @media screen and (min-width: 900px) {

  &:hover {
    opacity: 1;
    cursor: $SpineCursor;
  }
  }
}

// .recaptchaContainer {
//   // position: absolute !important;
//   z-index: 100000 !important;
//   color: red !important;
//   border: 5px solid red !important;
// }