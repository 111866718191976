@import '../../Colors.scss';

.servicesContainer {
    background-color: #fff;
    width: 100% !important;
}

.servicesContainer {
    border-radius: 8px;
    display: flex !important;
    flex-wrap: wrap;
    align-items: center !important;
    justify-content: center !important;
    gap: 30px !important;
    // justify-content: space-between !important;   
    // justify-content: space-evenly !important;   
    // justify-content: space-around !important;  
}

.servicesContainerBox {
    margin-bottom: 20px; 
}

@media (max-width: 750px) {

    .servicesContainerBox {
        margin: auto;
      }
      
  }


.ServicesTitle {
    color : $GoldenColor !important;
    // margin: 32px 0px !important;
    margin:  0 auto !important;
}

// .servicesPics {
//     width: 250px;
//     height: 250px;
//     border-radius: 8px;
//     aspect-ratio: 3/2 !important;
//     // object-fit: contain;
// }

// .servicesText {
//     width: 75%;
//     text-align: center;
//     vertical-align: middle !important;
// }

.ShowMoreButton {
    background-color: $GoldenColor !important;
    color: $White !important;
    cursor: $SpineCursor;
}
