@import '../Colors.scss';

@media (min-width: 600px){
.css-p7bec2-MuiTypography-root {
    display: block !important;
    text-align: left !important;
}
}

.MobileDrawer {
    background-color: $White !important;
}

.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    background-color: $White !important;
}

.ButtonsOfMobileDrawer {
    color: $GoldenColor !important;
}

.css-i4bv87-MuiSvgIcon-root {
    color: $GoldenColor !important;
}

.MuiSvgIcon-root .MuiSvgIcon-fontSizeMedium .css-vubbuv {
    color: $GoldenColor !important;
}

.burgerMenu {
    color: $GoldenColor !important;

}

.ClinicLogo {
    width: 100px;
    margin-top: 5px;
}

.transparentbg {
    background-color: transparent !important;
    color: black;
    box-shadow: none !important;

    .ButtonItems {
        color: $White !important;
        
    &:hover {
        cursor: $SpineCursor;   
       }
        }

    .css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
            background-color: $GoldenColor !important;
            cursor: $SpineCursor;   

        }
}

.notTransparent {
    background-color: $White !important;
    color: black;
    box-shadow: none !important;
    border-bottom: 2px solid $GoldenColor;
    
    .ButtonItems {
    color: $GoldenColor !important;

    &:hover {
        cursor: $SpineCursor;   
       }
    }

    .css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
        background-color: rgba($GoldenColor , 0.5) !important;
        cursor: $SpineCursor;   

    }
}

.NavLink  {
    text-decoration: none !important;
    color: $GoldenColor !important;
    touch-action: manipulation !important;
    -webkit-tap-highlight-color: transparent !important;
}