@import '../../Colors.scss';

.faqContainer {
    width: 80%;
}

.pointer {
    cursor: $SpineCursor;
}

.color {
    color: gray !important;
}
