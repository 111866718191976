@import '../../Colors.scss';

.quoteContainer {
    height: 200px;
    display: flex !important;
    align-items: center; 
    justify-content: center; 
    color: $White;
    background-color: $GoldenColor; // Set a background color for the container


    @media screen and (max-width: 900px) {
        height: 400px;
    }
}

.quoteText {
    text-align: center;
    color: $White; // Set a different color for the text
    max-width: 600px; // Adjust the maximum width as needed
    font-family:Georgia, 'Times New Roman', Times, serif !important;
    padding-left: 5px ;
    padding-right: 5px ;
}
