@import '../../Colors.scss';

.LogoSliderWrapper {
//   position: absolute;
//   z-index: -1 !important;
  width: 100%;
  height: 250px;
//   margin-top: -100px;

  .LogoStyle {
    margin: 10px !important;
    max-width: 150px !important;
    height: 100px !important;
    // aspect-ratio: 3/4;
    object-fit: contain;
    // mix-blend-mode: color-burn !important;
    // filter: grayscale(100%);
    
    @media screen and (max-width: 600px) {
      width: 90px !important;
    aspect-ratio: 3/4;
    }
  }

  .MarqStyle1 {
    overflow: hidden !important;
    width: 100% !important;

    // margin-bottom: 20px !important;
  }
}


.PartnersTitle {
    color : $White !important;
    // margin: 32px 0px !important;
    // margin:  20px  !important;
    
    
    // background-color: transparent !important;
  }
