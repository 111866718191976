@import './colors.scss';

// *:not([cursor="auto"]) {
//   cursor: url(../src/assets/logos/image-cursor2.png), pointer !important;
// }
body::-webkit-scrollbar{
  display: none;
}
.App {
  text-align: center;
  // background-color: $GoldenColor;
  background-color: $White !important;
  // overflow: hidden !important;
  // cursor: $SpineCursor;
}

.VideoTag {
  width: 100% !important;
  pointer-events: none; /* Disable user interaction with the video */
}

.HeaderButton {
  // background-color: $GoldenColor !important;
  // background: linear-gradient(to right, $GoldenColor , $Black) !important;
  background: $whatsapp !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  margin-bottom: 16px !important;
  
    &::before {
    content: '';
    position: absolute;
    width: 110%;
    height: 150%;
    border-radius: 8px;
    background-color: rgba($whatsapp , 0.5);
    border: 2px solid rgba($whatsapp , 0.8);
    animation: pulse 2s infinite ease-in-out; /* Adjust the animation duration and timing function */
    z-index: -1; /* Place the pseudo-element below the button content */
  }

  @media screen and (max-width: 600px) {
    font-size: 10px !important;
    margin-bottom: 5px !important;

  }
  
}
@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 0.6;
  }
  50% {
    transform: scale(1.2);
    opacity: 0;
  }
}

.HeaderButton:hover {
  // background-color: red !important;
  transition: background-color 0.3s ease;
  cursor: $SpineCursor;   
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // font-size: 24px;
  color: $White;
  font-weight: bold;
  // margin-top: 20px !important;

  @media screen and (max-width: 900px) {
    top: 20%;
  }

  @media screen and (max-width: 500px) {
    white-space: nowrap;
    font-size: 14px !important;
    top: 15%;
  }

}

/* Optionally hide controls completely */
.VideoTag::-webkit-media-controls {
  display: none !important;
}

.SocialICON {
  width: 45px !important;
  height: 45px !important;
  border: 1px solid $White;
  color: $White !important;
 
  border-radius: 50%;
  padding: 5px;

  
  @media screen and (max-width: 600px) {
    width: 35px !important;
  height: 35px !important;
  }

}

.SocialAnchors {
  margin: 5px 10px 10px 0 !important;
  border-radius: 0 0 100% 100%;
  
  width: 45px !important;
  height: 45px !important;
  cursor: auto !important;
 
  &:hover {
    cursor: $SpineCursor;  
  }

  @media screen and (max-width: 600px) {
    width: 35px !important;
  height: 35px !important;
  }
  
}


.partnersContainer {
  width: 100% !important;
  background-color: $GoldenColor !important;
}

.servicesContainer {
  width: 100% !important;
  background-color: #fff !important;
}

.quoteContainer {
  width: 100% !important;
  background-color: $GoldenColor !important;
  // box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px !important;
}

.technologiesContainer {
  width: 100% !important;
  // background-color: #fff !important;
}

.faqContainer {
  width: 100% !important;
}

.contactusContainer {
  width: 100% !important;
  background-color: #fff !important;
}

.ContactusAppContainer {
  height: 100vh !important;
  overflow-y: hidden !important;
}