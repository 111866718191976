@import '../../Colors.scss';

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
//   display: flex;
  background: #333;
  justify-content: flex-end;
  align-items: flex-end;
//   min-height: 100vh;
}

.footer {
  position: relative;
  width: 100%;
  background: #BD9943;
  // #BB9844
  min-height: 100px;
  padding: 20px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.social-icon,
.menu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  flex-wrap: wrap;
}

.social-icon__item,
.menu__item {
  list-style: none;
}

.social-icon__link {
  font-size: 2rem;
  color: #fff;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
}
.social-icon__link:hover {
  transform: translateY(-10px);
}

.menu__link {
  font-size: 1.2rem;
  color: #fff;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
  text-decoration: none;
  opacity: 0.75;
  font-weight: 300;
}

.menu__link:hover {
  opacity: 1;
}

.footer p {
  color: #fff;
  margin: 15px 0 10px 0;
  font-size: 1rem;
  font-weight: 300;
}

.wave {
  position: absolute;
  // top: -100px;
  top: -95px;
  left: 0;
  width: 100%;
  height: 100px;
  // background: url("https://i.ibb.co/wQZVxxk/wave.png") ;
  background: url("../../assets/footer/wave.png") ;
  background-size: 1000px 100px;
}

.wave#wave1 {
  // z-index: 1000;
  opacity: 1;
  // background-image: $GoldenColor !important;
  background-image: #BD9943 !important;
  bottom: 0;
  animation: animateWaves 4s linear infinite;
}

.wave#wave2 {
  // z-index: 999;
  opacity: 0.5;
  bottom: 10px;
  animation: animate 4s linear infinite !important;
}

.wave#wave3 {
  // z-index: 1000;
  opacity: 0.2;
  bottom: 15px;
  animation: animateWaves 3s linear infinite;
}

.wave#wave4 {
  // z-index: 999;
  opacity: 0.7;
  bottom: 20px;
  animation: animate 3s linear infinite;
}

@keyframes animateWaves {
  0% {
    background-position-x: 1000px;
  }
  100% {
    background-positon-x: 0px;
  }
}

@keyframes animate {
  0% {
    background-position-x: -1000px;
  }
  100% {
    background-positon-x: 0px;
  }
}

.SocialAnchorsFooter {
    margin: 5px 10px 10px 0 !important;
    border-radius: 0 0 100% 100%;
    
    width: 45px !important;
    height: 45px !important;
    cursor: auto !important;
   
    &:hover {
      cursor: $SpineCursor;  
    }
  
    @media screen and (max-width: 600px) {
      width: 35px !important;
    height: 35px !important;
    }
  }

.SocialICONFooter {
  width: 45px !important;
  height: 45px !important;
//   border: 1px solid $White;
  color: $White !important;
 
  border-radius: 50%;
  padding: 5px;

  
  @media screen and (max-width: 600px) {
    width: 35px !important;
  height: 35px !important;
  }

}