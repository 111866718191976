@import '../../Colors.scss';

.technologiesContainer {
    width: 100%;
    // height: 400px !important;
    background-color: #fff !important;
}


body {
  // background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}
  
  .swiper {
    width: 70%;
    padding-top: 20px;
    padding-bottom: 50px;
    // height: 80vh !important;
    
    @media (max-width: 900px) {
        width: 100% !important;
        // height: 70vh !important;
    }
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;

    // .overlayHover {
    //   display: none;
    // }

    .overlayHover {
    // &:hover {

      position: absolute; 
      top: 0; 
      // background: rgb(0, 0, 0);
      background: rgba(Black , 0.5); /* Black see-through */
      // color: #f1f1f1; 
      width: 100%;
      transition: .5s ease;
      opacity:0;
      color: white !important;
      font-size: 20px;
      padding: 20px 0px;
      text-align: center;
      opacity: 1;
    // }
    }
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
  }
  
  .overlay {
    position: absolute; 
    bottom: 0; 
    // background: rgb(0, 0, 0);
    background: rgba($GoldenColor , 0.5); /* Black see-through */
    // color: #f1f1f1; 
    width: 100%;
    transition: .5s ease;
    opacity:0;
    color: $White !important;
    font-size: 20px;
    padding: 20px 0px;
    text-align: center;
    opacity: 1;
  }

  .technologiesTitle {
    color : $GoldenColor !important;
    // margin: 32px 0px !important;
    // margin:  32px auto !important;
    
    // background-color: transparent !important;
  }

  .mySwiper {
    // cursor: $SpineCursor;
    // background-color: transparent !important;
  }