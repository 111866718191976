@import '../../Colors.scss';

.cardContainer {
    // border: 2px solid $GoldenColor;
    background-color: $White !important;
    color:  $GoldenColor !important;
    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}

.cardContent {
    border-top: 2px solid $GoldenColor;
}

// .cardText {
    //     color:  $GoldenColor !important;
    // }
    
.cardLearnMore {
    color:  $GoldenColor !important;
    cursor: $SpineCursor;

    @media screen and (min-width: 900px) {

    &:hover { 
          background-color: rgba($GoldenColor , 0.5) !important;
  }
  }
}

.CardMediaImage {
    // background-size: contain !important; this was used for the CardMedia
    width: 70%;
    aspect-ratio: 3/2;
    object-fit: contain;
    // mix-blend-mode: color-burn;
}